import React from 'react';
import Presence from '../Components/Home/Presence';
import IntroMachine from '../Components/MachineComponent/IntroMachine';
import '../Styles/MachineComponent.css';
import DescriptionMachine from '../Components/MachineComponent/DescriptionMachine';
import MachineSpecs from '../Components/MachineComponent/MachineSpecs';
import RelatedProducts from '../Components/MachineComponent/RelatedProducts';
import MachineSpecs2 from '../Components/MachineComponent/MachineSpecs2';
import MultiTable from '../Components/MachineComponent/MultiTable';
import MachineSpecs3 from '../Components/MachineComponent/MachineSpecs3';
import { Helmet } from 'react-helmet';
import MachineSpecs4 from '../Components/MachineComponent/MachineSpecs4';
import PropTypes from 'prop-types';

const MachineComponent = ({ visitData, ...props }) => {
    const data = props.data || {};

    const renderMachineSpecs3 = () => {
        const specsArray = [
            { img: props.img1url, specs: data.Table1Specs, heading: data.Table1Heading },
            { img: props.imgurls, specs: data.Table2Specs, heading: data.Table2Heading },
            { img: props.img3url, specs: data.Table3Specs, heading: data.Table3Heading },
            { img: props.img4url, specs: data.Table4Specs, heading: data.Table4Heading },
        ];

        return specsArray.map((item, index) =>
            item.specs ? (
                <MachineSpecs3
                    key={index}
                    imglink={item.img}
                    labels={data.MachineSpecsLabels}
                    data={item.specs}
                    Heading={item.heading}
                    visitData={visitData}
                />
            ) : null
        );
    };

    const renderMachineSpecs = () => {
        if (!data.ExpandedTable && !data.newTable && !data.tablenumber4) {
            return (
                <MachineSpecs
                    labels={data.MachineSpecsLabels}
                    data={data.MachineSpecs}
                    Heading={data.Table1Heading}
                />
            );
        }
        if (data.newTable) return renderMachineSpecs3();
        if (data.ExpandedTable) return <MachineSpecs2 labels={data.MachineSpecsLabels} data={data.MachineSpecs} />;
        if (data.tablenumber4) return <MachineSpecs4 labels={data.MachineSpecsLabels} data={data.MachineSpecs} />;
        if (data.MultipleTable) return <MultiTable labels={data.MultipleLabels} data={data.MultiTabelData} />;
        return null;
    };

    return (
        <>
            <Helmet>
                <link rel="apple-touch-icon" href="/logo.iso" />
                <link rel="icon" type="image/x-icon" href="/logo.iso" />
                <title>{data.seotitle || 'Default SEO Title'}</title>
                {data.seometa?.map((meta, index) => (
                    <meta key={index} name={meta.name || 'default-name'} content={meta.content || 'default-content'} />
                ))}
                <link rel={data.seolink?.name || 'canonical'} href={data.seolink?.url || '/'} />
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16529601205"></script>
                <script async src="https://cdn.pagesense.io/js/nesscoindia/726a5bfbad6c4f5091eda44cd7ec1cb1.js"></script>
            </Helmet>

            {/* Intro Section */}
            <IntroMachine
                heading={data.Heading}
                route={data.Route}
                description={data.Description}
                price={data.Price}
                showPrice={data.ShowPrice}
                visitData={visitData}
            />

            {/* Description Section */}
            <DescriptionMachine
                down={props.down}
                setdown={props.setdown}
                data={data}
                imgurl={props.imgurl}
                imgurls={props.imgurls}
                img2url={props.img2url}
                img3url={props.img3url}
                img4url={props.img4url}
                visitData={visitData}
            />

            {/* Conditional Table Rendering */}
            {renderMachineSpecs()}

            {/* Related Products */}
            <RelatedProducts data={data.RelatedProducts} />
            <Presence visitData={visitData} />
        </>
    );
};

MachineComponent.propTypes = {
    visitData: PropTypes.object,
    data: PropTypes.shape({
        seotitle: PropTypes.string,
        seometa: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        seolink: PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        }),
        Heading: PropTypes.string,
        Route: PropTypes.string,
        Description: PropTypes.string,
        Price: PropTypes.string,
        ShowPrice: PropTypes.bool,
        MachineSpecsLabels: PropTypes.array,
        MachineSpecs: PropTypes.array,
        RelatedProducts: PropTypes.array,
        newTable: PropTypes.bool,
        ExpandedTable: PropTypes.bool,
        tablenumber4: PropTypes.bool,
        MultipleTable: PropTypes.bool,
    }),
    down: PropTypes.any,
    setdown: PropTypes.func,
    imgurl: PropTypes.string,
    imgurls: PropTypes.string,
    img1url: PropTypes.string,
    img2url: PropTypes.string,
    img3url: PropTypes.string,
    img4url: PropTypes.string,
};

MachineComponent.defaultProps = {
    visitData: {},
    data: {},
};

export default MachineComponent;
